<template>
  <div class="success">
    <div class="image">
      <img src="../assets/submit-success.png" alt="" />
    </div>
    <p class="title">提交成功！</p>
    <p class="subtitle">银行卡修改已提交审核，请等待审核通过后再进行提现操作</p>
    <div class="btn"><button @click="$router.replace('/income')">返回我的收益</button></div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
@import "../styles/initialization.scss";
.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 28px;
  .image {
    img {
      width: 79px;
      height: 60px;
    }
  }
  .title {
    margin-bottom: 12px;
    width: 100px;
    text-align: center;
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    padding-left: 21px;
    // text-align: CENTER;
    color: #222222;
  }
  .subtitle {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #666660;
    margin-bottom: 20px;
  }
  .btn {
      padding-left:17px;
    button {
        cursor: pointer;
      width: 124px;
      height: 40px;
      background: #ff004d;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      border: none;
    }
  }
}
</style>